import OrderStatusChange, {
    SerializedOrderStatusChange,
} from "@/models/order-status-changes";
import {
    mdiCancel,
    mdiCashRefund,
    mdiCheckCircle,
    mdiPlus,
    mdiPlusCircle,
} from "@mdi/js";
import { Location } from "vue-router";
import { ROUTE_NAMES } from "@/router";

export const ORDER_STATUS = {
    PLACED: "placed",
    PAYMENT_FAILED: "payment failed",
    PAYMENT_CANCELLED: "payment cancelled",
    PAYMENT_SUCCESSFULL: "payment successful",
    PAYMENT_REFUNDED: "payment refunded",
    FULFILLMENT_FAILED: "fulfillment failed",
    FULFILLMENT_SUCCESSFULL: "fulfillment successful",
    FULFILLMENT_VERIFIED: "fulfillment-verified",
};

export const PAYMENT_METHODS = {
    MTN_MOBILE_MONEY: "mtn-mobile-money",
    ORANGE_MONEY: "orange-money",
    BANK_CARD: "bank-card",
    CRYPTOCURRENCY: "cryptocurrency",
};

export interface SerializedOrder {
    order_id: string;
    order_reference: string;
    order_status: string;
    customer_id: string;
    customer_name: string | null;
    customer_address: string | null;
    customer_email: string;
    bill_id: string | null;
    order_status_changes: Array<SerializedOrderStatusChange>;
    customer_payment_method: string;
    customer_payment_phone_number: string;
    dstv_package_id: string;
    dstv_package_name: string;
    smart_card_number: string;
    payment_gateway_name: string;
    credits_added: number | null;
    customer_bank_card_number: string | null;
    payment_requested_at: string;
    payment_completed_at: string | null;
    payment_amount: number;
    fulfilled_at: string | null;
    created_at: string;
    fulfillment_provider_id: string | null;
    fulfillment_provider_fees: number | null;
    accounting_transaction_id: string | null;
    payment_provider_checkout_url: string | null;
    receipt_pdf_is_generated: boolean;
    is_new_customer: boolean;
    is_stuck: boolean;
    category: string;
    quantity: number;
    source: string;
    is_referred: boolean;
    referral_message: string | null;
    referral_code: string | null;
    referral_name: string | null;
    recipient_phone_number: string;
    referral_email: string | null;
    payment_currency: string;
    payment_provider_name: string | null;
    payment_provider_amount: string | null;
    usd_xaf_rate: number | null;
    verified: boolean | null;
    verified_by: string | null;
    verified_at: string | null;
}

export default class Order {
    private readonly _orderId: string;
    private readonly _orderReference: string;
    private readonly _orderStatus: string;
    private readonly _customerId: string;
    private readonly _customerEmail: string;
    private readonly _customerName: string | null;
    private readonly _customerAddress: string | null;
    private readonly _customerPaymentMethod: string;
    private readonly _customerPaymentPhoneNumber: string;
    private readonly _itemId: string;
    private readonly _itemName: string;
    private readonly _smartCardNumber: string;
    private readonly _paymentRequestedAt: Date;
    private readonly _paymentCompletedAt: Date | null;
    private readonly _paymentAmount: number;
    private readonly _creditsAdded: number | null;
    private readonly _fulfilledAt: Date | null;
    private readonly _category: string;
    private readonly _createdAt: Date;
    private readonly _recipientPhoneNumber: string;
    private readonly _orderStatusChanges: Array<OrderStatusChange>;
    private readonly _paymentProviderCheckoutUrl: string | null;
    private readonly _receiptPdfIsGenerated: boolean;
    private readonly _accountingTransactionId: string | null;
    private readonly _isNewCustomer: boolean;
    private readonly _paymentGatewayName: string;
    private readonly _isStuck: boolean;
    private readonly _source: string;
    private readonly _quantity: number;
    private readonly _isReferred: boolean;
    private readonly _referralCode: string | null;
    private readonly _referralMessage: string | null;
    private readonly _referralName: string | null;
    private readonly _referralEmail: string | null;
    private readonly _fulfillmentProviderId: string | null;
    private readonly _fulfillmentProviderFees: number | null;
    private readonly _billId: string | null;
    private readonly _customerBankCardNumber: string | null;
    private readonly _paymentProviderName: string | null;
    private readonly _paymentCurrency: string;
    private readonly _paymentProviderAmount: string | null;
    private readonly _usdXafRate: number | null;
    private readonly _verified: boolean | null;
    private readonly _verifiedBy: string | null;
    private readonly _verifiedAt: Date | null;

    constructor(order: SerializedOrder) {
        this._orderId = order.order_id;
        this._customerName = order.customer_name;
        this._customerAddress = order.customer_address;
        this._orderReference = order.order_reference;
        this._orderStatus = order.order_status;
        this._customerId = order.customer_id;
        this._customerEmail = order.customer_email;
        this._customerPaymentMethod = order.customer_payment_method;
        this._customerPaymentPhoneNumber = order.customer_payment_phone_number;
        this._itemId = order.dstv_package_id;
        this._itemName = order.dstv_package_name;
        this._creditsAdded = order.credits_added;
        this._category = order.category;
        this._source = order.source;
        this._recipientPhoneNumber = order.recipient_phone_number;
        this._smartCardNumber = order.smart_card_number;
        this._paymentRequestedAt = new Date(order.payment_requested_at);
        this._paymentCompletedAt = order.payment_completed_at
            ? new Date(order.payment_completed_at)
            : null;
        this._paymentAmount = order.payment_amount;
        this._fulfilledAt = order.fulfilled_at
            ? new Date(order.fulfilled_at)
            : null;
        this._createdAt = new Date(order.created_at);
        this._paymentProviderCheckoutUrl = order.payment_provider_checkout_url;
        this._receiptPdfIsGenerated = order.receipt_pdf_is_generated;
        this._orderStatusChanges = order.order_status_changes.map(
            (status: SerializedOrderStatusChange) => {
                return new OrderStatusChange(status);
            }
        );
        this._accountingTransactionId = order.accounting_transaction_id;
        this._isNewCustomer = order.is_new_customer;
        this._paymentGatewayName = order.payment_gateway_name;
        this._isStuck = order.is_stuck;
        this._quantity = order.quantity;
        this._isReferred = order.is_referred;
        this._referralMessage = order.referral_message;
        this._referralCode = order.referral_code;
        this._referralName = order.referral_name;
        this._referralEmail = order.referral_email;
        this._fulfillmentProviderId = order.fulfillment_provider_id;
        this._fulfillmentProviderFees = order.fulfillment_provider_fees;
        this._billId = order.bill_id;
        this._paymentProviderName = order.payment_provider_name;
        this._customerBankCardNumber = order.customer_bank_card_number;
        this._paymentCurrency = order.payment_currency;
        this._paymentProviderAmount = order.payment_provider_amount;
        this._usdXafRate = order.usd_xaf_rate;
        this._verified = order.verified;
        this._verifiedBy = order.verified_by;
        this._verifiedAt = order.verified_at
            ? new Date(order.verified_at)
            : null;
    }

    get verified(): boolean | null {
        return this._verified;
    }

    get verifiedBy(): string | null {
        return this._verifiedBy;
    }

    get verifiedAt(): Date | null {
        return this._verifiedAt;
    }

    get paymentProviderName(): string | null {
        return this._paymentProviderName;
    }

    get customerAddress(): string | null {
        return this._customerAddress;
    }

    get paymentProviderAmount(): string | null {
        return this._paymentProviderAmount;
    }

    get billId(): string | null {
        return this._billId;
    }

    get fulfillmentProviderFees(): number | null {
        return this._fulfillmentProviderFees;
    }

    get category(): string {
        return this._category;
    }

    get recipientPhoneNumber(): string {
        return this._recipientPhoneNumber;
    }

    get isAirtime(): boolean {
        return this._category === "airtime";
    }

    get isExchange(): boolean {
        return this._category === "exchange";
    }

    get isDstv(): boolean {
        return this._category === "dstv";
    }

    get isCanalplus(): boolean {
        return this._category === "canalplus";
    }
    get isEneo(): boolean {
        return this._category.toLocaleLowerCase().includes("eneo");
    }

    get fulfillmentProviderId(): string | null {
        return this._fulfillmentProviderId;
    }

    get eneoCode(): string | null {
        if (!this._fulfillmentProviderId) {
            return null;
        }
        const parts = this._fulfillmentProviderId.split(" : ");
        return parts[0];
    }

    get isEneoPrepaid(): boolean {
        return this._itemName.toLocaleLowerCase().includes("eneo pre");
    }

    get isEneoPostpaid(): boolean {
        return this._itemName.toLocaleLowerCase().includes("eneo post");
    }

    get isCamwater(): boolean {
        return this._category.toLocaleLowerCase().includes("camwater");
    }

    get isCryptoSell(): boolean {
        return this._category.toLocaleLowerCase().includes("crypto-sell");
    }

    get usdXafRate(): number | null {
        return this._usdXafRate;
    }

    get meterKiloWatts(): string | null {
        if (!this._fulfillmentProviderId) {
            return null;
        }
        const parts = this._fulfillmentProviderId.split(" : ");
        return parts[2];
    }

    get referralName(): string | null {
        return this._referralName;
    }

    get referralEmail(): string | null {
        return this._referralEmail;
    }

    get referralCode(): string | null {
        return this._referralCode;
    }

    get isReferred(): boolean {
        return this._isReferred && !this.isFailedAtPayment;
    }

    get isWhatsappOrder(): boolean {
        return this._source.includes("whatsapp");
    }

    get referralMessage(): string | null {
        return this._referralMessage;
    }

    get isMultipleQuantity(): boolean {
        return this.quantity > 1;
    }
    get quantity(): number {
        return this._quantity === 0 ? 1 : this._quantity;
    }

    get isStuck(): boolean {
        return this._isStuck;
    }

    get paymentGatewayName() {
        return this._paymentGatewayName;
    }

    get isNewCustomer(): boolean {
        return this._isNewCustomer;
    }

    get creditsAdded(): number | null {
        return this._creditsAdded;
    }

    get customerName(): string | null {
        if (this._customerName) {
            return this._customerName;
        }
        return null;
    }

    get orderStatusChanges(): Array<OrderStatusChange> {
        return this._orderStatusChanges;
    }

    get receiptPdfIsGenerated(): boolean {
        return this._receiptPdfIsGenerated;
    }

    get link(): Location {
        return {
            name: ROUTE_NAMES.ORDERS_SHOW,
            params: {
                orderId: this.orderId,
            },
        };
    }
    get orderReference(): string {
        return this._orderReference;
    }

    get customerId(): string {
        return this._customerId;
    }

    get customerEmail(): string | null {
        return this._customerEmail;
    }

    get isCancelled(): boolean {
        return this._orderStatus === ORDER_STATUS.PAYMENT_CANCELLED;
    }

    get customerPaymentMethod(): string {
        if (this._paymentProviderName !== null) {
            return this._paymentProviderName;
        }

        switch (this._customerPaymentMethod) {
            case PAYMENT_METHODS.MTN_MOBILE_MONEY:
                return "MTN Mobile Money";
            case PAYMENT_METHODS.ORANGE_MONEY:
                return "Orange Money";
            case PAYMENT_METHODS.BANK_CARD:
                return "Bank Card";
            case PAYMENT_METHODS.CRYPTOCURRENCY:
                return "CryptoCurrency";
        }
        return "";
    }

    get hasMobilePayment(): boolean {
        return (
            this._customerPaymentMethod === PAYMENT_METHODS.MTN_MOBILE_MONEY ||
            this._customerPaymentMethod === PAYMENT_METHODS.ORANGE_MONEY
        );
    }

    get customerBankCardNumber(): string | null {
        return this._customerBankCardNumber;
    }

    get hasBankCardPayment(): boolean {
        return this._customerPaymentMethod === PAYMENT_METHODS.BANK_CARD;
    }

    get hasCryptoCurrencyPayment(): boolean {
        return this._customerPaymentMethod === PAYMENT_METHODS.CRYPTOCURRENCY;
    }

    get customerPaymentPhoneNumber(): string {
        return this._customerPaymentPhoneNumber;
    }

    get itemId(): string {
        return this._itemId;
    }

    get itemName(): string {
        return this._itemName;
    }

    get itemWithoutPrice(): string {
        return this._itemName.substr(0, this.itemName.indexOf("("));
    }

    get smartCardNumber(): string {
        return this._smartCardNumber;
    }

    get paymentRequestedAt(): Date {
        return this._paymentRequestedAt;
    }

    get paymentCompletedAt(): Date | null {
        return this._paymentCompletedAt;
    }

    get singleItemAmount(): number {
        return this.paymentAmount / this.quantity;
    }

    get paymentAmount(): number {
        return this._paymentAmount;
    }

    get hasPaymentProviderAmount(): boolean {
        return (
            this._paymentProviderAmount !== null &&
            this._paymentProviderAmount != ""
        );
    }

    get paymentCurrency(): string {
        return this._paymentCurrency;
    }

    get fulfilledAt(): Date | null {
        return this._fulfilledAt;
    }

    get referralTime(): Date {
        return this._orderStatusChanges[this._orderStatusChanges.length - 1]
            .changedAt;
    }

    get isFulfilled(): boolean {
        return this.orderStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL;
    }

    get isFailedAtFulfillment(): boolean {
        return this.orderStatus === ORDER_STATUS.FULFILLMENT_FAILED;
    }

    get isFailedAtPayment(): boolean {
        return (
            [
                ORDER_STATUS.PAYMENT_CANCELLED,
                ORDER_STATUS.PAYMENT_FAILED,
            ].indexOf(this.orderStatus) !== -1
        );
    }

    get isRefunded(): boolean {
        return this.orderStatus === ORDER_STATUS.PAYMENT_REFUNDED;
    }

    get isInProgress(): boolean {
        return (
            [
                ORDER_STATUS.PLACED,
                ORDER_STATUS.PAYMENT_SUCCESSFULL,
                ORDER_STATUS.FULFILLMENT_FAILED,
            ].indexOf(this.orderStatus) !== -1
        );
    }

    get isAwaitingFulfillment(): boolean {
        return this.orderStatus === ORDER_STATUS.PAYMENT_SUCCESSFULL;
    }

    get isFulfilling(): boolean {
        return this.isAwaitingFulfillment || this.isFailedAtFulfillment;
    }

    get isAwaitingPayment(): boolean {
        return this.orderStatus === ORDER_STATUS.PLACED;
    }
    get isPayedUsingMTNMobileMoney(): boolean {
        return this._customerPaymentMethod === "mtn-mobile-money";
    }

    get isMobilePayment(): boolean {
        return (
            this._customerPaymentMethod === "mtn-mobile-money" ||
            this._customerPaymentMethod === "orange-money"
        );
    }

    get requiresPaymentRedirect(): boolean {
        return (
            this.orderStatus === ORDER_STATUS.PLACED &&
            this.paymentProviderCheckoutUrl !== null
        );
    }

    get paymentProviderCheckoutUrl(): string | null {
        return this._paymentProviderCheckoutUrl;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get createdAtTimestamp(): number {
        return this.createdAt.getTime();
    }

    get orderId(): string {
        return this._orderId;
    }

    get receiptDownloadURL() {
        return (
            process.env.VUE_APP_API_BASE_URL +
            `/orders/` +
            this.orderId +
            `/receipt`
        );
    }

    get statusColor(): string {
        if (this.needsMaintenance) {
            return "purple darken-3";
        }
        const colors: Record<string, string> = {
            [ORDER_STATUS.PLACED]: "grey darken-3",
            [ORDER_STATUS.PAYMENT_FAILED]: "red darken-4",
            [ORDER_STATUS.PAYMENT_CANCELLED]: "red darken-4",
            [ORDER_STATUS.PAYMENT_SUCCESSFULL]: "orange darken-3",
            [ORDER_STATUS.PAYMENT_REFUNDED]: "red darken-2",
            [ORDER_STATUS.FULFILLMENT_FAILED]: "red darken-4",
            [ORDER_STATUS.FULFILLMENT_SUCCESSFULL]: "green darken-3",
        };
        return colors[this.orderStatus];
    }

    get needsMaintenance(): boolean {
        return (
            (this.orderStatus === ORDER_STATUS.PAYMENT_REFUNDED &&
                this._accountingTransactionId === null &&
                new Date().getTime() -
                    this._orderStatusChanges[
                        this._orderStatusChanges.length - 1
                    ].changedAt.getTime() >
                    5 * 60 * 1000) ||
            (!this.isStuck &&
                (this.orderStatus == ORDER_STATUS.FULFILLMENT_FAILED ||
                    (this.orderStatus ===
                        ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
                        this._accountingTransactionId === null))) ||
            (this.orderStatus == ORDER_STATUS.PAYMENT_SUCCESSFULL &&
                new Date().getTime() - this.createdAt.getTime() >
                    10 * 60 * 1000) /* 10 mins */
        );
    }

    get statusIcon(): string {
        const colors: Record<string, string> = {
            [ORDER_STATUS.PLACED]: mdiPlusCircle,
            [ORDER_STATUS.PAYMENT_FAILED]: mdiCancel,
            [ORDER_STATUS.PAYMENT_CANCELLED]: mdiCancel,
            [ORDER_STATUS.PAYMENT_SUCCESSFULL]: mdiPlus,
            [ORDER_STATUS.PAYMENT_REFUNDED]: mdiCashRefund,
            [ORDER_STATUS.FULFILLMENT_FAILED]: mdiCancel,
            [ORDER_STATUS.FULFILLMENT_SUCCESSFULL]: mdiCheckCircle,
        };
        return colors[this.orderStatus];
    }

    get orderStatus(): string {
        return this._orderStatus;
    }
}
