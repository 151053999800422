
import { Component, Vue, Watch } from "vue-property-decorator";
import OrderApi from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import Order, { SerializedOrder } from "@/models/order";
import {
    mdiCheckAll,
    mdiCloseThick,
    mdiContentCopy,
    mdiHistory,
    mdiCashRefund,
    mdiCheckDecagram,
    mdiWhatsapp,
} from "@mdi/js";
import { Getter } from "vuex-class";
import {
    DatatableFooterProps,
    DefaultFooterProps,
    parseFilterOptionsFromParams,
} from "@/types/veutify";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import OrderQuantity from "@/components/OrderQuantity.vue";
import { DataOptions, DataTableHeader } from "vuetify";
import SearchInput from "@/components/SearchInput.vue";
import { ApiFilterOptions, ApiResponse } from "@/services/api/axios";
import { addParamsToLocation } from "@/router";
import { captureSentryException } from "@/plugins/sentry";

@Component({
    components: { SearchInput, OrderQuantity, LoadingButton, LoadingSpinner },
})
export default class AdminOrdersIndex extends Vue {
    loadingOrders: boolean = true;
    orders: Array<Order> = [];
    defaultQuery: string = "";
    query: string = "";
    footerProps: DatatableFooterProps = DefaultFooterProps;
    isMaintaining = false;
    isRegisteringSuccessfulPayment = false;
    isRegisteringFailedPayment = false;
    retryIcon: string = mdiHistory;
    successIcon: string = mdiCheckAll;
    mdiCashRefund: string = mdiCashRefund;
    copyIcon: string = mdiContentCopy;
    failedIcon: string = mdiCloseThick;
    mdiWhatsapp: string = mdiWhatsapp;
    totalItems: number = -1;
    mdiCheckDecagram: string = mdiCheckDecagram;
    unverifiedOrdersSwitch: boolean = false;
    datatableOptions: DataOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["createdAtTimestamp"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };

    @Getter("authToken") authToken!: string;

    @Watch("datatableOptions")
    onOptionsChanged() {
        this.loadOrders();
    }

    get headers(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: "Order ID",
                sortable: false,
                value: "orderReference",
            },
            {
                text: "Ordered At",
                value: "createdAtTimestamp",
            },
            {
                text: "Item Name",
                value: "itemName",
            },
            {
                text: "Amount Paid",
                value: "paymentAmount",
            },
            {
                text: "Status",
                value: "orderStatus",
            },
        ];

        if (this.$vuetify.breakpoint.lgAndUp) {
            headers.push({
                text: "Customer ID",
                value: "customerId",
            });
        }

        headers.push(
            ...[
                {
                    text: "Customer Email",
                    value: "customerEmail",
                },
                {
                    text: "Details",
                    value: "data-table-expand",
                },
            ]
        );

        return headers;
    }

    get filteredOrders(): Array<Order> {
        if (this.unverifiedOrdersSwitch) {
            return this.orders.filter((order) => order.verified === false);
        }
        return this.orders;
    }

    get apiFilterOptions(): ApiFilterOptions {
        const headersMap = new Map<string, string>([
            ["createdAtTimestamp", "created_at"],
            ["itemName", "item.name"],
            ["paymentAmount", "total_payment_amount"],
            ["orderStatus", "status"],
        ]);
        return {
            itemsPerPage: this.datatableOptions.itemsPerPage,
            page: this.datatableOptions.page,
            query: this.query,
            sortBy:
                headersMap.get(this.datatableOptions.sortBy[0]) ?? "created_at",
            sortDesc: this.datatableOptions.sortDesc[0],
        };
    }

    setDefaultPrams() {
        const result = parseFilterOptionsFromParams(this.$route, {
            ...this.datatableOptions,
        });
        this.datatableOptions = result.options;
        this.defaultQuery = result.query ?? this.defaultQuery;
        this.query = this.defaultQuery;
    }

    mounted() {
        this.setDefaultPrams();
        this.loadOrders();
    }

    loadOrders() {
        addParamsToLocation(this.$route, this.apiFilterOptions);
        this.loadingOrders = true;
        OrderApi.adminIndex(this.apiFilterOptions)
            .then((response: AxiosResponse) => {
                this.orders = response.data.data.items.map(
                    (order: SerializedOrder) => {
                        return new Order(order);
                    }
                );
                this.totalItems = response.data.data.total;
                this.loadingOrders = false;
            })
            .catch(this.handleAxiosError);
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    onQueryChanged(query: string) {
        this.query = query;
        this.datatableOptions = {
            ...this.datatableOptions,
            page: 1,
        };
        this.loadOrders();
    }

    registerFailedPayment(orderId: string) {
        this.isRegisteringFailedPayment = true;
        OrderApi.adminRegisterFailedPayment(orderId)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message
                );
                this.loadOrders();
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isRegisteringFailedPayment = false;
            });
    }

    registerSuccessfulPayment(orderId: string) {
        this.isRegisteringSuccessfulPayment = true;
        OrderApi.adminRegisterSuccessfulPayment(orderId)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message
                );
                this.loadOrders();
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isRegisteringSuccessfulPayment = false;
            });
    }

    async copyOrderId(orderId: string) {
        try {
            await navigator.clipboard.writeText(orderId ?? "");
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                `"${orderId}" has been copied to your clipboard`
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    maintainOrder(orderId: string) {
        this.isMaintaining = true;
        OrderApi.adminMaintain(orderId)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message
                );
                this.loadOrders();
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isMaintaining = false;
            });
    }

    verifyOrder(orderId: string) {
        this.isMaintaining = true;
        OrderApi.adminVerify(orderId)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message
                );
                this.loadOrders();
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isMaintaining = false;
            });
    }

    refundOrder(orderId: string) {
        this.isMaintaining = true;
        OrderApi.adminRefund(orderId)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message
                );
                this.loadOrders();
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isMaintaining = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
